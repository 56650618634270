<template>
	<div id="ground-transport">
		<div class="go-back">
			<router-link :to="'/plan'">
				<i class="fas fa-arrow-left"></i>&nbsp;&nbsp;
				Go back
			</router-link>
		</div>

		<div class="content-ground">
			<div class="trans-mongolian-Railway">
				<div class="moscow">
					<span>Moscow  -  Ulaanbaatar   -  Beijing</span>
				</div>
				<div class="description">
					<p class="title">Trans-Mongolian Railway</p>
					<p class="text">The Trans-Mongolian Railway follows an ancient tea-caravan route from China to Russia and connects Ulan-Ude, on the Trans-Baikal railway in Russia, with the Chinese city of Jining, by way of Ulaanbaatar in Mongolia. Other important stops are Sükhbaatar, Darkhan, Choir, and Zamyn-Üüd/Erenhot.</p>
					<a href="#" class="btn btn-trans btn-block" @click="showMap">View Route Map</a>
					<a href="https://www.transsiberianexpress.net/" class="btn btn-trans btn-block" target="_blank" rel="noopener noreferrer">Trans-Siberian Express</a>
				</div>
			</div>
			<div class="ground-transport">
				<div class="description">
					<p class="title">Overland</p>
					<p class="text">Mongolia can be accessed overland by car or train through a limited number of border crossings from China and Russia. It is advised to plan carefully to have all necessary papers ready if you plan to do the trip by car. Please also note restrictions you might have when travelling with your own vehicle through China.</p>
				</div>
				<mapSvg class="map1" :class="{'isHide':mapShow}"/>
				<mapSvg2 class="map2" :class="{'isShow':mapShow}" @hideMap="hideMap" />
				
			</div>
		</div>
		
	</div>
</template>

<script>
import moscow from '../../assets/img/moscow2.jpg'
import mapSvg from './MapMongoliaSvg'
import mapSvg2 from './RouteMapSVG'

export default {
	  name: "GroundTransport",
	  components: {
		  mapSvg,
		  mapSvg2
	  },
	  data() {
		  return {
			  moscow : moscow,
			  mapShow : false
		  }
	  },
	  methods : {
		  hideMap() {
			  this.mapShow = false;
		  },
		  showMap() {
			  this.mapShow = !this.mapShow;
		  }
	  }
};
</script>

<style lang="scss" scoped>
	#ground-transport {
		width: 100%;
		height: 100%;
		padding: 0 5.71% 0;

		.go-back {
			width: 100%;
			height: 12.10%;
			display: flex;
			align-items: center;

			.router-link-active {
				color: #E13036;
				font-weight: bold;
			}
		}

		.content-ground {
			display: flex;
			flex-wrap: wrap;
			height: 87.90%;
			position: relative;
			.scrolldown {
				cursor: pointer;
				position: absolute;
				left: 50%;
				bottom: 20px;
				transform: translate(-50%);
				width: 40px;
				z-index: 2;
			}

			.trans-mongolian-Railway {
				width: 25%;
				background-color: #E13036;

				.moscow {
					position: relative;
					width: 100%;
					height: 25.76%;
					background-image: url('../../assets/img/moscow2.jpg');
					background-size: cover;

					span {
						position: absolute;
						left: 5px;
						bottom: 5px;
						color: #FFFFFF;
						font-size: 12px;
					}
				}

				.description {
					padding: 20px 25px 0;
					p {
						color: #FFFFFF;
					}
					.title {
						font-size: 18px;
						font-weight: bold;
					}

					.text {
						font-size: 10px;
					}

					.btn-trans {
						border: 1px #FFFFFF solid;
						border-radius: 0px;
						font-size: 10px;
						color: #FFFFFF;
					}
				}
			}

			.ground-transport {
				width: calc(75% - 40px);
				padding:0 50px;

				.description {
					.title {
						font-size: 33px;
						font-weight: bold;
						margin: 0;
					}

					.text {
						font-size: 12px;
					}

					.mapsvg {
						width: 80%;
					}
				}

				.map1 {
					opacity: 1;
					z-index: 2;
					transition: opacity 0.5s;
					&.isHide {
						opacity: 0;
					}
				}

				.map2 {
					opacity: 0;
					transition: opacity 0.5s;
					&.isShow {
						opacity: 1;
					}
				}
			}
		}

		@media only screen and (min-width: 1920px) {
            .go-back {
				.router-link-active {
					font-size: 20px;
				}
			}

			.content-ground {
				.scrolldown {
					width: inherit;
				}

				.trans-mongolian-Railway {
					.moscow {
						span {
							left: 10px;
							bottom: 10px;
							font-size: 16px;
						}
					}

					.description {
						padding: 40px 30px 0;

						.title {
							font-size: 30px;
						}

						.text {
							font-size: 14px;
						}

						.btn-trans {
							font-size: 16px;
						}
					}
				}

				.ground-transport {
					width: calc(75% - 40px);
					padding:0 50px;

					.description {
						.title {
							font-size: 50px;
						}

						.text {
							font-size: 15px;
						}
					}
				}
			}
        }
	}
</style>